// ** Projects Images
import lorealImg from "./assets/imgs/projects/loreal.png";
import porrtoDoAcuImg from "./assets/imgs/projects/porto.png";
import nexaImg from "./assets/imgs/projects/nexa.png";
import nestleImg from "./assets/imgs/projects/nestle.png";

// ** Logo Carousel
import logo1 from "./assets/imgs/logos/assai.png";
import logo2 from "./assets/imgs/logos/brf.png";
import logo3 from "./assets/imgs/logos/carrefour.png";
import logo4 from "./assets/imgs/logos/loreal_brasil.png";
import logo5 from "./assets/imgs/logos/loreal_group.png";
import logo6 from "./assets/imgs/logos/nexa.png";
import logo7 from "./assets/imgs/logos/porto_do_acu.png";
import logo8 from "./assets/imgs/logos/sipal.png";
import logo9 from "./assets/imgs/logos/nestle.png";

export const projectsData = [
  {
    id: 1,
    image: lorealImg,
    title: "L’Oréal - Programa de Reconhecimento",
    path: "/projeto/loreal",
    description: "Liderou o processo end-to-end do Programa de Reconhecimento em 4 divisões da L’Oréal atuando como Product Designer e Product Owner. O App é uma plataforma B2B exclusiva para os colaboradores internos de cada divisão da L'Oréal. Este aplicativo permite que os funcionários troquem reconhecimentos entre si, celebrando atitudes exemplares e conquistas em projetos.",
  },
  {
    id: 2,
    image: porrtoDoAcuImg,
    title: "CORE - Porto do Açu",
    path: "/projeto/core",
    description: "Liderou o processo end-to-end do aplicativo desenvolvido para o CORE (Centro de Operações e Resposta a Emergências) do Porto do Açu atuando como Product Designer e Product Owner. O App do CORE é um produto B2B2C, este aplicativo permite que os operadores recebam solicitações de emergências e prestem socorro para a população do Porto do Açu e seu entorno.",
  },
  {
    id: 3,
    image: nexaImg,
    title: "Nexa - Desenvolvimento Social e Local",
    path: "/projeto/nexa",
    description: "Liderou o processo end-to-end do sistema de Desenvolvimento Social e Local desenvolvido para a Nexa Mineradora atuando como Product Designer e Product Owner. O sistema inclui um aplicativo para os usuários finais e três perfis de gerenciamento, com o objetivo de promover capacitação profissional e ampliar oportunidades de emprego para as comunidades onde a mineradora atua.",
  },
  {
    id: 4,
    image: nestleImg,
    title: "Panela Nestlé",
    path: "/projeto/nestle",
    description: "Liderou o desafio comercial da Panela Nestlé, com o objetivo de fortalecer a relação da marca com microempreendedores culinários por meio de um novo produto. Atuou como Product Designer e Product Owner, conduzindo o processo de ponta a ponta para garantir uma solução estratégica e impactante.",
  },
];

export const aboutInfo = [
  {
    id: 1,
    text: "User Experience",
  },
  {
    id: 2,
    text: "UX Research",
  },
  {
    id: 3,
    text: "Usability Test",
  },
  {
    id: 4,
    text: "Benchmarking",
  },
  {
    id: 5,
    text: "Design System",
  },
  {
    id: 6,
    text: "User Interface Design",
  },
  {
    id: 7,
    text: "Wireframes",
  },
  {
    id: 8,
    text: "Prototype",
  },
  {
    id: 9,
    text: "Responsive Design",
  },
  {
    id: 10,
    text: "Design Thinking",
  },
  {
    id: 11,
    text: "HTML/CSS",
  },
  {
    id: 12,
    text: "Figma",
  },
];

export const carouselLogoData = [
  {
    id: 1,
    image: logo1,
    name: "Assaí Atacadista",
  },
  {
    id: 2,
    image: logo2,
    name: "BRF",
  },
  {
    id: 3,
    image: logo3,
    name: "Carrefour",
  },
  {
    id: 4,
    image: logo4,
    name: "L'Oréal Brasil",
  },
  {
    id: 5,
    image: logo5,
    name: "L'Oréal Group",
  },
  {
    id: 6,
    image: logo6,
    name: "Nexa",
  },
  {
    id: 7,
    image: logo7,
    name: "Porto do Açu",
  },
  {
    id: 8,
    image: logo8,
    name: "SIPAL",
  },
  {
    id: 9,
    image: logo9,
    name: "Nestlé",
  },
]