import { useEffect } from "react"
import { Container } from "react-bootstrap"
import loreal from '../../assets/imgs/projects/loreal/loreal.jpg'

export default function Loreal() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [])

  return (
    <section className="loreal">
      <Container>
        <img src={loreal} alt="Loreal Prototype" />
      </Container>
    </section>
  )
}