import { Container } from "react-bootstrap"
import { projectsData } from '../data'
import SingleProjectDiv from "../components/SingleProjectDiv"

export default function Projects() {
  return (
    <section id="projects">
      <Container>
        <h2>Projetos</h2>
        <div className="projects-container">
          {projectsData.map((proj) => {
            return (
              <SingleProjectDiv key={proj.id} {...proj} />
            )
          })}
        </div>
      </Container>
    </section>
  )
}