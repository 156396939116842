import { Link } from "react-router-dom";

export default function SingleProjectDiv({ image, title, path, description }) {
    return (
        <div className="project-div">
            <Link to={path}>
                <div className="project-card">
                    <img src={image} alt={title} />
                    <div className="project-card-text">
                        <h4>{title}</h4>
                        <p>{description}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}