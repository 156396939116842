import { Container } from "react-bootstrap"
import { BsBehance } from 'react-icons/bs'
import { FaLinkedinIn, FaDribbble } from 'react-icons/fa'

export default function Footer() {
    return (
        <Container fluid className="footer-wrapper">
            <footer>
                <Container className="footer">
                    <div>
                        &copy;  {new Date().getFullYear()} by Karla Alcantara.
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/in/karla-barreto" target="_blank" rel="noreferrer">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://dribbble.com/karlaalcanttara" target="_blank" rel="noreferrer">
                            <FaDribbble />
                        </a>
                        <a href="https://www.behance.net/karlaalcanttara" target="_blank" rel="noreferrer">
                            <BsBehance />
                        </a>
                    </div>
                </Container>
            </footer>
        </Container>
    )
}