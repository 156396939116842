import { useEffect } from "react";
import { Container } from "react-bootstrap";
import coreImg from "../../assets/imgs/projects/core/core.jpg";

export default function PortoDoAcu() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="portodoacu">
      <Container>
        <img src={coreImg} alt="Porto do Açu Prototype" />        
      </Container>
    </section>
  );
}
