import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCurrentContext } from "../context/context";
import logo from "../assets/imgs/logos/logo_karla.png";

function NavbarBt() {
  const { setCurrent } = useCurrentContext();
  const location = useLocation();
  const navigate = useNavigate();

  function scrollToSection(id) {
    const { pathname } = location;
    if (pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          const offset = element.offsetTop - 105;
          window.scrollTo({
            top: offset,
            behavior: "smooth",
          });
        }
      }, 200);
    } else {
      const element = document.getElementById(id);
      if (element) {
        const offset = element.offsetTop - 105;
        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      }
    }
  }

  return (
    <Navbar collapseOnSelect expand="md" fixed="top">
      <Container>
        <Navbar.Brand as={Link} to="/" className="home">
          <img
            src={logo}
            alt="Logo"
            className="logo"
            onClick={() => {
              scrollToSection("home");
              setCurrent("home");
            }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href=""
              eventKey="2"
              className="projects"
              onClick={() => {
                scrollToSection("projects");
                setCurrent("projects");
              }}
            >
              Projetos
            </Nav.Link>
            <Nav.Link
              href=""
              eventKey="1"
              className="about"
              onClick={() => {
                scrollToSection("about");
                setCurrent("about");
              }}
            >
              Sobre mim
            </Nav.Link>
            <Nav.Link
              href=""
              eventKey="3"
              className="contact"
              onClick={() => {
                scrollToSection("contact");
                setCurrent("contact");
              }}
            >
              Contato
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarBt;
