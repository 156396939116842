import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "../components/ContactForm";
import { useForm } from "@formspree/react";

export default function Contact() {
  const [state, handleSubmit] = useForm("moqzzvyo");

  if (state.succeeded) {
    return (
      <section id="contact">
        <h2>Entre em contato</h2>
        <Container className="return-msg-container">
          <p className="return-p-msg">
            Obrigado pela sua mensagem. Retornarei em breve.
          </p>
        </Container>
      </section>
    );
  }

  return (
    <section id="contact">
      <h2>Entre em contato</h2>
      <Container className="pt-5">
        <Row className="contact-row">
          <Col lg={6} md={6} sm={12} xs={12} className="text-contact">
            <p>
              Gostou do meu trabalho? Fique a vontade para me mandar um e-mail
              para conversarmos e iniciarmos um novo projeto.
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="contact-form-div">
              <ContactForm state={state} handleSubmit={handleSubmit} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
