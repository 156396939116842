import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import NavbarBt from "./components/NavbarBt";
import Footer from "./components/Footer";
import ErrorPage from "./pages/ErrorPage";
import Home from './pages/Home'
import ScrollSpy from "./components/ScrollSpy";
import { CurrentProvider } from "./context/context";

//** Single Projects
import Loreal from "./pages/single-projects/Loreal";
import PortoDoAcu from "./pages/single-projects/PortoDoAcu";
import Nexa from "./pages/single-projects/Nexa";
import Nestle from "./pages/single-projects/Nestle";

export default function App() {
  return (
    <Router>
      <CurrentProvider>
        <NavbarBt />
        <ScrollSpy />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projeto/loreal' element={<Loreal />} />
          <Route path='/projeto/core' element={<PortoDoAcu />} />
          <Route path='/projeto/nexa' element={<Nexa />} />
          <Route path='/projeto/nestle' element={<Nestle />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
        <Footer />
      </CurrentProvider>
    </Router>
  )
}