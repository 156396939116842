import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { carouselLogoData } from '../data';

// Import Swiper styles
import 'swiper/css';
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay]);

export default function Carousel() {
  const [swiperLogo, setSwiperLogo] = useState(null);

  return (
    <Swiper
      onSwiper={setSwiperLogo}
      spaceBetween={0}
      slidesPerView={2}
      freeMode={true}
      loop={true}
      onAutoplayStop={() => swiperLogo.autoplay.start()}
      autoplay={{ delay: 1000 }}
      breakpoints={{
        1100: {
          spaceBetween: 100,
          slidesPerView: 4
        },
        600: {
          spaceBetween: 25,
          slidesPerView: 3
        },
        450: {
          spaceBetween: 0,
          slidesPerView: 2
        },
      }}
    >
      {carouselLogoData.map((item) => (
        <SwiperSlide key={item.id}>
          <img className="logo-carousel" src={item.image} alt={item.name} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};