import { useEffect } from "react";
import { Container } from "react-bootstrap";
import nexa from '../../assets/imgs/projects/nexa/nexa.jpg'

export default function Nexa() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="nexa">
      <Container>
        <img src={nexa} alt="Loreal Prototype" />
      </Container>
    </section>
  );
}
