import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import logo from "../assets/imgs/logos/logo_karla.png";
import Carousel from "../components/Carousel";

export default function HomeFirstPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const logoElem = document.querySelector(".home-logo");
      if (logoElem) {
        logoElem.style.display = "none";
      }
    }, 2000);
  }, []);

  return (
    <section id="home">
      <Container>
        <div className="home-logo">
          <motion.img
            src={logo}
            alt="Logo"
            initial={{ y: "100vh", rotate: 0 }}
            animate={{
              y: "15vh",
              rotate: 360,
            }}
            transition={{ duration: 2, type: "spring", stiffness: 20 }}
          />
        </div>
        <div className="home-wrapper">
          <Row>
            <Col xl={8} lg={8} md={12} sm={12} xs={12}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 2 }}
              >
                <h2 className="h2-home">Karla Alcantara</h2>
                <p className="home-p">Product Designer especializada em soluções digitais B2B e SaaS, com experiência em todo o ciclo de desenvolvimento, desde pesquisa e ideação até a prototipação e entrega final com o propósito de transformar desafios em produtos que fazem a diferença na vida das pessoas e dos negócios.</p>
              </motion.div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 2 }}
              >
                <p className="home-p text-center mb-4">Empresas onde já atuei</p>
                <Carousel />
              </motion.div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}
