import { useEffect } from "react";
import { Container } from "react-bootstrap";
import nestle from '../../assets/imgs/projects/nestle/nestle.jpg'

export default function Nestle() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="nestle">
      <Container>
      <img src={nestle} alt="Nestlé Prototype" />
      </Container>
    </section>
  );
}
